import { useContext, useEffect, useRef, useState, useCallback } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import NavLinks from "@components/NavLinks";

import LogoUncovered from "assets/svgs/uncovered.svg";
import IconHamburger from "assets/svgs/hamburger.svg";
import Glass from "assets/svgs/glass.svg";
import { useUser } from "@auth0/nextjs-auth0/client";
import { OutsetaContext } from "../../pages/_app";

import useAnalytics from "hooks/useAnalytics";

const redirectUrl = process.env.NEXT_PUBLIC_EMAIL_SIGNUP_REDIRECT_URL;

export default function Header() {
  const [isHeaderHidden, setIsHeaderHidden] = useState(true);

  const { user } = useUser();

  const { track, identify, analyticsReset } = useAnalytics();

  const {
    outsetaUserId,
    addOutsetaUserId,
    addOutsetaFirstName,
    addOutsetaUserCaseList,
    addOutsetaUserPlanType,
    addOutsetaUserPlanId,
    updateOutsetaStatus,
    outsetaRef,
  } = useContext(OutsetaContext);

  const router = useRouter();

  const auth0clientId =
    process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID ||
    "oVXZZ1k3fbeDQMyLfOQfDXLGzrIO5xAB";

  useEffect(() => {
    console.log(`outseta`, outsetaRef.current);
    if (outsetaRef.current === null) {
      outsetaRef.current = window.Outseta;
    }
  }, [outsetaRef]);

  useEffect(() => {

    function handleSleekNoteEvent(e) {
      console.log("sleeknote event", e);
      if (e.data && e.data.type === "submit") {
        
        if (e.data.data && e.data.data.email) {
          const email = e.data.data.email;
          console.log("sleeknote email:", email);
          if (email && redirectUrl && window && window.location && window.location.href) {
            window.location.href = `${redirectUrl}?email=${email}`;
          }
        }
        else {
          console.warn("expected data not in sleeknote submit event data");
        }
      }
    }

    if (document && document.addEventListener) {
      console.log("adding sleeknote event listener");
      document.addEventListener('sleekNote', handleSleekNoteEvent, false);
    }
    
    // Clean up the event listener when the component unmounts
    return () => {
      if (document && document.removeEventListener) {
        console.log("removing sleeknote event listener");
        document.removeEventListener("sleekNote", handleSleekNoteEvent);
      }
    };

  }, []);

  // Outseta logout - > since it's on onClick need something in case they click too fast
  const uncLogout = useCallback(
    () => {
      console.log("logout");

      if (outsetaRef.current) {
        outsetaRef.current.on("logout", function () {
          analyticsReset();
          window.location.href = `/api/auth/logout?returnTo=/&client_id=${auth0clientId}`;
        });
        outsetaRef.current.logout();
      } else {
        console.error("No outseta on logout");
      }
    }, [auth0clientId, outsetaRef]);

  const fetchOutsetaToken = useCallback(
    async () => {
      const tokenRes = await fetch("/api/outseta-token");

      if (tokenRes.ok) {
        const tokenObj = await tokenRes.json();

        if (outsetaRef.current) {
          outsetaRef.current.setAccessToken(tokenObj?.access_token);

          // TODO: Do we need to keep tokenObj.expires_in to smart refresh here?
          // token expires every 14 days - does outseta library store it locally?
          // is there a way to check whether token has expired - how long does this context stay alive?
          const outsetaUser = await outsetaRef.current.getUser();

          if (outsetaUser) {
            addOutsetaUserId(outsetaUser.Uid);
            addOutsetaUserCaseList(outsetaUser.CaseList);
            addOutsetaUserPlanType(
              outsetaUser.Account.CurrentSubscription.Plan.Name
            );
            addOutsetaUserPlanId(
              outsetaUser.Account.CurrentSubscription.Plan.Uid
            );
            addOutsetaFirstName(outsetaUser.FirstName);
            updateOutsetaStatus("ready");

            identify(outsetaUser.Uid, {
              Plan: outsetaUser.Account.CurrentSubscription.Plan.Name,
              FirstName: outsetaUser.FirstName,
              LastName: outsetaUser.LastName,
            });
            track("user_logged_in");
          } else {
            console.error("no outseta user");
          }
        }
      }
    }, [
      addOutsetaFirstName, 
      addOutsetaUserCaseList, 
      addOutsetaUserId, 
      addOutsetaUserPlanId, 
      addOutsetaUserPlanType, 
      updateOutsetaStatus,
      outsetaRef.current
    ]);

  useEffect(() => {
    if (!user && outsetaUserId !== "") {
      console.log("no user but outseta token still set - logging out");
      uncLogout();
    }

    if (user && window.Outseta && outsetaUserId === "" && fetchOutsetaToken) {
      fetchOutsetaToken();
    }
  }, [user, outsetaUserId, fetchOutsetaToken, uncLogout]);

  let headerRef = useRef();

  const hideHeader = () => {
    const header = headerRef?.current || { style: {} };
    const headerHeight = header?.offsetHeight;

    header.style.transition = "all 300ms ease-in";
    header.style.transform = `translateY(-${headerHeight}px)`;

    const tabNav = document?.getElementById("case-navigation") || { style: {} };
    tabNav.style.transition = "all 300ms ease-in";
    tabNav.style.transform = `translateY(0)`;

    setIsHeaderHidden(true);
  };

  const showHeader = (appHeaderPosition, tabNavPosition) => {
    const header = headerRef?.current || { style: {} };
    const headerHeight = header?.offsetHeight;

    header.style.transition = "all 300ms ease-in";
    header.style.transform = `translateY(0)`;

    const tabNav = document?.getElementById("case-navigation") || { style: {} };
    if (appHeaderPosition < tabNavPosition) {
      tabNav.style.transform = "translateY(0)";
    } else {
      tabNav.style.transition = "all 300ms ease-in";
      tabNav.style.transform = `translateY(${headerHeight}px)`;
    }
  };

  useEffect(() => {
    if (typeof window === "undefined") return;
    let lastScroll = window?.scrollY;

    window?.addEventListener("scroll", () => {
      const currentScroll = window?.scrollY;
      const appHeaderPosition =
        headerRef?.current?.getBoundingClientRect()?.bottom;
      const tabNavPosition = document
        ?.getElementById("case-navigation")
        ?.getBoundingClientRect()?.top;
      if (
        router.pathname.includes("/cases/[slug]") &&
        router.pathname !== "/cases"
      ) {
        // If app header is at or below tab nav and scrolling down, hide app header
        // else show app header if scrolling up
        if (currentScroll > lastScroll && appHeaderPosition >= tabNavPosition) {
          hideHeader();
        } else if (currentScroll < lastScroll) {
          showHeader(appHeaderPosition, tabNavPosition);
        }
      }

      lastScroll = currentScroll;
    });
  }, [router.pathname, isHeaderHidden]);

  const HeaderContent = () => (
    <>
      {" "}
      <NavLinks parent="header" />
      <div className="order-3 lg:flex lg:flex-shrink-0 lg:ml-auto lg:py-3 xl:pl-6">
        {user && (
          <>
            <Link href="/profiles/workspace">
              <a className="border-b border-gray-100 border-solid font-semibold p-3 text-xs lg:border-0 lg:ml-auto lg:mr-6 lg:p-2">
                My Workspace
              </a>
            </Link>
            <button
              onClick={() => uncLogout()}
              className="border-b border-gray-100 border-solid font-semibold p-3 text-xs lg:border-0 lg:ml-auto lg:mr-6 lg:p-2"
            >
              Sign Out
            </button>
          </>
        )}
        {!user && (
          <>
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a
              href="/api/auth/login?returnTo=/profiles/workspace"
              className="block border-b border-gray-100 border-solid font-semibold p-3 text-sm lg:border-0 lg:ml-auto lg:mr-6 lg:p-2 lg:text-xs text-gray-580"
            >
              Login
            </a>
            {router?.pathname !== "/membership" &&
              router?.pathname !== "/join" && (
                <Link href="/join">
                  <a className="bg-red-600 text-xs hover:bg-red-700 duration-300 flex-shrink-0 font-semibold inline-flex ml-3 flex items-center mt-2 py-2 px-4 text-white transition uppercase lg:my-0 lg:ml-0 lg:py-0 rounded-full">
                    Become a Member
                  </a>
                </Link>
              )}
          </>
        )}
      </div>
      <div className="hidden align-middle lg:flex pl-3">
        <Link href={"/cases#case-search"} className="flex align-middle">
          <button
            className="ml:auto"
            onClick={() => {
              setTimeout(() => {
                document.documentElement.scrollTop =
                  document?.documentElement?.scrollTop - 120;
              }, 0);
            }}
          >
            <Glass className="mr-4" />
          </button>
        </Link>
        <button
          className="ml:auto lg:hidden"
          onClick={() => setIsHeaderHidden(!isHeaderHidden)}
        >
          <IconHamburger className="mr-4" />
        </button>
      </div>
    </>
  );

  //header is relative on the case page because there is another navigation through the case itself
  return (
    <header
      ref={headerRef}
      className={`bg-header z-50 transform shadow xl:px-0 w-full sticky top-0 left-0`}
    >
      <div className="bg-white py-4">
        <div className="max-w-6xl	mx-auto px-2">
          <nav className="flex justify-between lg:items-center">
            <Link href="/">
              <a className="my-4 mr-2 lg:my-0">
                <LogoUncovered width="215" />
              </a>
            </Link>
            <div
              className={` absolute duration-500 ease-out left-0 ${
                !isHeaderHidden
                  ? "bg-white h-screen max-h-screen "
                  : "h-0 max-h-0"
              } overflow-hidden right-0 top-full transition-all z-10 lg:hidden lg:flex-grow lg:static lg:h-auto lg:max-h-full`}
            >
              <HeaderContent />
            </div>

            <div
              className={` hidden absolute duration-500 ease-out left-0 ${
                !isHeaderHidden
                  ? "bg-white h-80 max-h-screen "
                  : "h-0 max-h-0"
              } right-0 top-full transition-all z-10 lg:flex lg:flex-grow lg:static lg:h-auto lg:max-h-full`}
            >
              <HeaderContent />
            </div>
            <div className="flex align-middle lg:hidden">
              <Link href={"/cases#case-search"} className="flex align-middle">
                <button
                  className="ml:auto"
                  onClick={() => {
                    setTimeout(() => {
                      document.documentElement.scrollTop =
                        document?.documentElement?.scrollTop - 120;
                    }, 0);
                  }}
                >
                  <Glass className="mr-4" />
                </button>
              </Link>
              <button
                className="ml:auto lg:hidden"
                onClick={() => setIsHeaderHidden(!isHeaderHidden)}
              >
                <IconHamburger className="mr-4" />
              </button>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}
