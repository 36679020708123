import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import  ChevronDownIcon  from "assets/svgs/caret-down.svg";
import { Disclosure } from "@headlessui/react";
const MegaNavLinks = ({ data }) => {
  return (
    <>
      <Popover className="relative hidden lg:block">
        <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
          <span>{data.label}</span>
          <ChevronDownIcon className="h-3 w-3 mt-[2px]" aria-hidden="true" />
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 mt-5 -left-1/4 flex w-screen max-w-max -translate-x-1/2 px-4">
            <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-red-100">
              <div className="p-4">
                {data.sublinks.map((item) => (
                  <div
                    key={item.label}
                    className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
                  >
                    <div>
                      <a
                        href={item.link}
                        className="font-semibold text-gray-900"
                      >
                        {item.label}
                        <span className="absolute inset-0" />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full lg:hidden justify-between rounded-lg  px-4 py-2 text-left text-sm font-semibold text-gray-900  ">
              <span>Resources</span>
              <ChevronDownIcon
                className={`${
                  open ? "rotate-180 transform" : ""
                } h-5 w-5 text-black`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
              {data.sublinks.map((item) => (
                <div
                  key={item.label}
                  className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
                >
                  <div>
                    <a href={item.link} className="font-semibold text-gray-900">
                      {item.label}
                      <span className="absolute inset-0" />
                    </a>
                  </div>
                </div>
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default MegaNavLinks;
