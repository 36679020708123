import { useRef, useEffect } from "react";

export default function useAnalytics() {

	// const gtag = useRef(null);
	const dataLayer = useRef(null);

	useEffect(() => {
		// gtag.current = window.gtag;
		dataLayer.current = window.dataLayer;
	}, []);

	function track(eventName, eventProperties = {}) {
		if (dataLayer.current && dataLayer.current.push) {
			try {
				dataLayer.current.push({
					'event': eventName,
					...eventProperties
				});
				console.log("gtm event track: ", eventName, eventProperties);
			}
			catch (e) {
				console.error(e);
			}
		}
		else {
			console.warn("GTM dataLayer not initialized?");
		}

	}

	function identify(userId, userProperties) {
		// if (mixpanel.current) {
		// 		try {
		// 			mixpanel.current.identify(userId);
		// 			if (userProperties) {
		// 				mixpanel.current.people.set(userProperties);
		// 			}
		// 			// console.log("Mixpanel user identified: ", userId, userProperties);
		// 		}
		// 		catch (e) {
		// 			console.error(e);
		// 		}
		// }
		// else {
		// 	console.warn("Mixpanel not initialized");
		// }
		console.log("analytics user identify: ", userId, userProperties);
	}

	function page(pageName, pageProperties) {
		// if (mixpanel.current) {
		// 	try {
		// 		// mixpanel.current.track(`viewed ${pageName}`, pageProperties);	
		// 		// console.log("Mixpanel page tracked: ", pageName, pageProperties);
		// 	}
		// 	catch (e) {
		// 		console.error(e);
		// 	}
		// }

		console.log("analytics page view: ", pageName, pageProperties);
	}

	function analyticsReset() {
		// if (mixpanel.current) {
		// 	try {
		// 		mixpanel.current.reset();
		// 		// console.log("Mixpanel user logged out");
		// 	}
		// 	catch (e) {
		// 		console.error(e);
		// 	}
		// }
		// else {
		// 	console.warn("Mixpanel not initialized");
		// }

		console.log("analytics reset (logged out)");
	}

	return {
		"track": track,
		"identify": identify,
		"page": page,
		"analyticsReset": analyticsReset,
	};
}